<template lang="pug">
v-container
  v-layout(row)
    v-flex(xs8)
      InkNotes
    v-flex(xs4).pl-3
      v-img(:src='design.image.url')
  v-layout.mt-3(row v-if='location')
    v-flex
      FwdCard
        template(slot="title") inks: {{location.template.name}}
        template(slot="content")
          InkList(:location='location') 
  v-layout.mt-3(row)
    v-flex
      FwdCard
        template(slot="title") progress
        template(slot="content")
          v-checkbox(
            v-model="isInksMixed"
            label="Inks mixed?"
            @change="save"
          )
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import Models from '@/models'
import InkList from '@/modules/designs/components/color-list'
import InkNotes from '@/modules/designs/components/notes/notes-ink'

export default {
  components: {
    InkList,
    InkNotes
  },
  computed: {
    ...mapFields('productionInstances', {
      item: 'item',
      isInksMixed: 'item.progress.isInksMixed'
    }),
    design() { return this.$store.getters['designs/item'] },
    location () {
      // return this.item.location
      const item = this.design
      return item.locations.find(loc => loc._id === this.item.location._id)
    },

  },
  methods: {
    findColorwayForLocation(location) {
      let foundColorway = location.colorways[0]
      return foundColorway
    },
    save () { this.$store.dispatch('productionInstances/_UPDATE') }
  }
}
</script>
