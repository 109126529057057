<template lang="pug">
div
  InkTicket
</template>

<script>
import InkTicket from './InkTicket'

export default {
  components: {
    InkTicket,
  }
}
</script>
